import raven from '../raven';

export default class Tracker {
    constructor(private readonly gtag: Gtag.Gtag, private readonly measurementId: string) {}

    event(action: string, category: string, label?: string | null, callback?: () => void): void {
        const params = {
            event_category: category,
        };

        if (label) {
            // @ts-ignore
            params.event_label = label;
        }

        if (callback) {
            // @ts-ignore
            params.event_callback = callback;
        }

        try {
            this.gtag('event', action, params);
        } catch (err) {
            raven.captureException(err as Error);
        }
    }

    pageview(path: string): void {
        try {
            this.gtag('config', this.measurementId, { page_path: path });
        } catch (err) {
            raven.captureException(err as Error);
        }
    }

    setUserProperties(p: { id: string; gender: string; roles: number[] | null }): void {
        try {
            this.gtag('set', { user_id: p.id });
            this.gtag('set', 'dimension3', p.gender);
            p.roles && this.gtag('set', 'dimension2', p.roles.join(','));
        } catch (err) {
            raven.captureException(err as Error);
        }
    }
}
