import { Raven } from '@/c/types/raven';

const mockRaven: Raven = {
    captureMessage: function (message: string, options: unknown) {
        console.log('Raven.captureMessage', message, options);
    },
    captureBreadcrumb: function (options: unknown) {
        console.log('Raven.captureBreadcrumb', options);
    },
    captureException: function (exception: Error) {
        console.log('Raven.captureException');
        console.error(exception);
    },
    setTagsContext: function (tags: Record<string, string>) {
        console.log('Raven.setTagsContext', tags);
    },
    setUserContext: function (context: unknown) {
        console.log('Raven.setUserContext', context);
    },
    flush: async function (time: number) {
        console.log('Raven.flush', time);
    },
};

const isClientSide = typeof window !== 'undefined';
const _default = isClientSide ? window.Raven : mockRaven;
export default _default;
