import Tracker from '@/c/defs/Tracker';
import Env from '@/c/Env';

const isClientSide = typeof window !== 'undefined';
const _default = new Tracker(isClientSide ? window.gtag : mockGtag, Env.googleAnalytics.measurementId);
export default _default;

function mockGtag() {
    // eslint-disable-next-line prefer-rest-params
    console.log(arguments);
}
